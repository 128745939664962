export default {
  SET_LATEST_ROUTE: 'SET_LATEST_ROUTE',
  UPDATE_COLUMNS: 'UPDATE_COLUMNS',
  UPDATE_SEARCH: 'UPDATE_SEARCH',
  UPDATE_SELECTED_ROW_SPACING_OPTION: 'UPDATE_SELECTED_ROW_SPACING_OPTION',
  UPDATE_TOTAL_ROWS_COUNT: 'UPDATE_TOTAL_ROWS_COUNT',
  EVENTS_ERROR: 'EVENTS_ERROR',
  UPDATE_COMPETITION_TEMPLATE_CHANGE: 'UPDATE_COMPETITION_TEMPLATE_CHANGE',
  UPDATE_EVENTS: 'UPDATE_EVENTS',
  UPDATE_OPTIONS: 'UPDATE_OPTIONS',
  SET_OPERATORS_LIST: 'SET_OPERATORS_LIST',

  SET_PREVIOUS_EVENT_LIST_OPTIONS: 'SET_PREVIOUS_EVENT_LIST_OPTIONS',
  UPDATE_EVENT_LIST_FIELDS: 'UPDATE_EVENT_LIST_FIELDS',
  UPDATE_EVENT_LIST: 'UPDATE_EVENT_LIST',
  UPDATE_EVENT_LIST_LOADING: 'UPDATE_EVENT_LIST_LOADING',
  UPDATE_SELECTED_EVENTS: 'UPDATE_SELECTED_EVENTS',
  IS_EVENTS_SIDEBAR_ACTIVE: 'IS_EVENTS_SIDEBAR_ACTIVE',
  IS_EVENTS_FILTERS_ACTIVE: 'IS_EVENTS_FILTERS_ACTIVE',

  SET_SIDEBAR_EXPANDED: 'SET_SIDEBAR_EXPANDED',
  SET_SIDEBAR_LOADING: 'SET_SIDEBAR_LOADING',
  SET_SIDEBAR_SPORTS: 'SET_SIDEBAR_SPORTS',
  SET_SIDEBAR_SPORTS_LOADING: 'SET_SIDEBAR_SPORTS_LOADING',
  SET_SIDEBAR_REGIONS: 'SET_SIDEBAR_REGIONS',
  SET_SIDEBAR_COMPETITIONS: 'SET_SIDEBAR_COMPETITIONS',
  SET_SIDEBAR_SELECTION: 'SET_SIDEBAR_SELECTION',

  SET_SUBSCRIPTION_MODAL_OPEN: 'SET_SUBSCRIPTION_MODAL_OPEN',
  SET_SUBSCRIPTION_MODAL_TYPE: 'SET_SUBSCRIPTION_MODAL_TYPE',
  SET_SUBSCRIPTION_MODAL_ID: 'SET_SUBSCRIPTION_MODAL_ID',

  UPDATE_EVENT: 'UPDATE_EVENT',
  UPDATE_EVENT_LOADING: 'UPDATE_EVENT_LOADING',
  UPDATE_EVENT_MARKETS: 'UPDATE_EVENT_MARKETS',
  UPDATE_EVENT_MARKETS_LOADING: 'UPDATE_EVENT_MARKETS_LOADING',
  UPDATE_EVENT_TEAM_PLAYERS: 'UPDATE_EVENT_TEAM_PLAYERS',

  UPDATE_SELECTED_TEMPLATE_MARKET: 'UPDATE_SELECTED_TEMPLATE_MARKET',
  UPDATE_SPORT_TEMPLATES: 'UPDATE_SPORT_TEMPLATES',
  UPDATE_JWT_TOKEN: 'UPDATE_JWT_TOKEN',
  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_USER_ROLES: 'UPDATE_USER_ROLES',
  UPDATE_OPERATOR: 'UPDATE_OPERATOR',
  UPDATE_LOGIN_ERROR_MESSAGE: 'UPDATE_LOGIN_ERROR_MESSAGE',
  UPDATE_SOCKET_CONNECTION: 'UPDATE_SOCKET_CONNECTION',
  UPDATE_CHANGE_PASSWORD_ATTRIBUTES: 'UPDATE_CHANGE_PASSWORD_ATTRIBUTES',

  SAVE_ALL_FEEDS: 'SAVE_ALL_FEEDS',
  SET_SUSPENDING_EVENT_IDS: 'SET_SUSPENDING_EVENT_IDS',
  SET_SUSPEND_MARKET_TYPE_ACTION_LOADING: 'SET_SUSPEND_MARKET_TYPE_ACTION_LOADING',
  SET_EVENT_LIST_MAPPING_EVENT: 'SET_EVENT_LIST_MAPPING_EVENT',

  SET_MARKET_GROUPS: 'SET_MARKET_GROUPS',
  SET_MARKET_FAVORITES: 'SET_MARKET_FAVORITES',

  SET_MAPPING_INITIALIZED: 'SET_MAPPING_INITIALIZED',
  SET_MAPPING_LOADING: 'SET_MAPPING_LOADING',
  SET_MAPPING_SPORTS: 'SET_MAPPING_SPORTS',
  SET_MAPPING_ENTRIES: 'SET_MAPPING_ENTRIES',
  SET_MAPPING_SELECTED_SPORT: 'SET_MAPPING_SELECTED_SPORT',
  SET_MAPPING_PRIMARY_FEEDS: 'SET_MAPPING_PRIMARY_FEEDS',
  SET_MAPPING_SECONDARY_FEEDS: 'SET_MAPPING_SECONDARY_FEEDS',
  SET_MAPPING_SELECTED_SEARCH_OPTION: 'SET_MAPPING_SELECTED_SEARCH_OPTION',
  SET_MAPPING_SEARCH_QUERY: 'SET_MAPPING_SEARCH_QUERY',
  SET_MAPPING_SELECTED_FEED: 'SET_MAPPING_SELECTED_FEED',
  SET_MAPPING_SELECTED_DATE: 'SET_MAPPING_SELECTED_DATE',
  SET_MAPPING_DISPLAY_STATE: 'SET_MAPPING_DISPLAY_STATE',
  SET_MAPPING_FEEDS_PER_SPORT: 'SET_MAPPING_FEEDS_PER_SPORT',
  SET_MAPPING_DISPLAYED_FEEDS: 'SET_MAPPING_DISPLAYED_FEEDS',

  SAVE_EVENT_SUGGESTED_MAPPINGS: 'SAVE_EVENT_SUGGESTED_MAPPINGS',
  SAVE_COMPETITOR_SUGGESTED_MAPPINGS: 'SAVE_COMPETITOR_SUGGESTED_MAPPINGS',
  SAVE_REGION_SUGGESTED_MAPPINGS: 'SAVE_REGION_SUGGESTED_MAPPINGS',
  SAVE_COMPETITION_SUGGESTED_MAPPINGS: 'SAVE_COMPETITION_SUGGESTED_MAPPINGS',
  SAVE_PLAYER_SUGGESTED_MAPPINGS: 'SAVE_PLAYER_SUGGESTED_MAPPINGS',
  SAVE_MAPPING_EVENTS: 'SAVE_MAPPING_EVENTS',
  SAVE_MAPPING_COMPETITORS: 'SAVE_MAPPING_COMPETITORS',
  SAVE_MAPPING_REGIONS: 'SAVE_MAPPING_REGIONS',
  SAVE_MAPPING_COMPETITIONS: 'SAVE_MAPPING_COMPETITIONS',
  SAVE_MAPPING_PLAYERS: 'SAVE_MAPPING_PLAYERS',
  UPDATE_MAPPING_LOADING: 'UPDATE_MAPPING_LOADING',

  SET_MANUAL_RESULTING_EVENT: 'SET_MANUAL_RESULTING_EVENT',
  SET_MANUAL_RESULTING_EVENT_LOADING: 'SET_MANUAL_RESULTING_EVENT_LOADING',
  SET_MANUAL_RESULTING_EVENTS: 'SET_MANUAL_RESULTING_EVENTS',
  UPDATE_MANUAL_RESULTING_EVENT_MARKETS: 'UPDATE_MANUAL_RESULTING_EVENT_MARKETS',
  UPDATE_MANUAL_RESULTING_MARKETS_LOADING: 'UPDATE_MANUAL_RESULTING_MARKETS_LOADING',

  // Player setup
  SET_PLAYER_SETUP_TABLE_DATA: 'SET_PLAYER_SETUP_TABLE_DATA',
  SET_PLAYER_SETUP_ORIGINAL_TABLE_DATA: 'SET_PLAYER_SETUP_ORIGINAL_TABLE_DATA',
  SET_PLAYER_SETUP_MAPPED_PLAYERS_FOR_PROJECTIONS: 'SET_PLAYER_SETUP_MAPPED_PLAYERS_FOR_PROJECTIONS',
  SET_PLAYER_SETUP_DATA: 'SET_PLAYER_SETUP_DATA',
  SET_IS_PLAYER_SETUP_DATA_LOADING: 'SET_IS_PLAYER_SETUP_DATA_LOADING',
  SET_IS_PLAYER_SETUP_PROJECTIONS_AVAILABLE: 'SET_IS_PLAYER_SETUP_PROJECTIONS_AVAILABLE',
  SET_IS_PLAYER_SETUP_SCOREBOARD_ACTIVE: 'SET_IS_PLAYER_SETUP_SCOREBOARD_ACTIVE',
  SET_PLAYER_SETUP_EVENT_SEARCH_ACTIVE: 'SET_PLAYER_SETUP_EVENT_SEARCH_ACTIVE',
  SET_PLAYER_SETUP_EVENT_LIST: 'SET_PLAYER_SETUP_EVENT_LIST',
  SET_PLAYER_SETUP_EVENT_LIST_LOADING: 'SET_PLAYER_SETUP_EVENT_LIST_LOADING',
  SET_TEAM_SQUAD_LIST_LOADING: 'SET_TEAM_SQUAD_LIST_LOADING',
  SET_IS_PLAYER_SETUP_SUBMIT_ENABLED: 'SET_IS_PLAYER_SETUP_SUBMIT_ENABLED',
  SET_IS_PLAYER_SETUP_SIMULATE_ENABLED: 'SET_IS_PLAYER_SETUP_SIMULATE_ENABLED',
  SET_IS_PLAYER_SETUP_SIMULATE_BY_SOCKET_CHANGE_ENABLED: 'SET_IS_PLAYER_SETUP_SIMULATE_BY_SOCKET_CHANGE_ENABLED',
  SET_NUMBER_OF_SIMULATE_ATTEMPTS: 'SET_NUMBER_OF_SIMULATE_ATTEMPTS',
  SET_IS_PLAYER_SETUP_PAGE_FROZEN: 'SET_IS_PLAYER_SETUP_PAGE_FROZEN',
  SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE: 'SET_FROZEN_PLAYER_SETUP_PAGE_MESSAGE',
  SET_IS_PLAYER_SETUP_PAGE_SIMULATE_BTN_FROZEN: 'SET_IS_PLAYER_SETUP_PAGE_SIMULATE_BTN_FROZEN',
  SET_AVAILABLE_MARKETS: 'SET_AVAILABLE_MARKETS',
  SET_IS_MARKETS_LOADING: 'SET_IS_MARKETS_LOADING',
  SET_PLAYERS_FOR_MARKETS_POPUP_LIST: 'SET_PLAYERS_FOR_MARKETS_POPUP_LIST',
  SET_PLAYER_SETUP_HIGHLIGHTED_PLAYER: 'SET_PLAYER_SETUP_HIGHLIGHTED_PLAYER',
  SET_PLAYER_SETUP_INFER_ACTIVE: 'SET_PLAYER_SETUP_INFER_ACTIVE',
  SET_PLAYER_SETUP_BEFORE_INFER_STATE: 'SET_PLAYER_SETUP_BEFORE_INFER_STATE',
  SET_PLAYER_PARAMS_SELECTED_MODE: 'SET_PLAYER_PARAMS_SELECTED_MODE',
  SET_PARAMS_MANAGER_MARKETS: 'SET_PARAMS_MANAGER_MARKETS',
  SET_PARAMS_MANAGER_MARKETS_BEFORE_INFER_STATE: 'SET_PARAMS_MANAGER_MARKETS_BEFORE_INFER_STATE',
  SET_PARAMS_MANAGER_GAME_PROJECTIONS_AVAILABLE: 'SET_PARAMS_MANAGER_GAME_PROJECTIONS_AVAILABLE',
  SET_PARAMS_MANAGER_LINEUP_PRESETS_BY_TEAM: 'SET_PARAMS_MANAGER_LINEUP_PRESETS_BY_TEAM',
  SET_PARAMS_MANAGER_SELECTED_LINEUP_PRESET_BY_TEAM: 'SET_PARAMS_MANAGER_SELECTED_LINEUP_PRESET_BY_TEAM',
  SET_PARAMS_MANAGER_SQUAD_DATA: 'SET_PARAMS_MANAGER_SQUAD_DATA',
  SET_PARAMS_MANAGER_SUSPEND_LOADING: 'SET_PARAMS_MANAGER_SUSPEND_LOADING',
  SET_AVAILABLE_MARKETS_PLAYER_TYPES: 'SET_AVAILABLE_MARKETS_PLAYER_TYPES',
  SET_PLAYER_SETUP_UNPUBLISHED_CHANGES: 'SET_PLAYER_SETUP_UNPUBLISHED_CHANGES',

  // Game params
  SET_PLAYER_SETUP_GAME_PARAMS: 'SET_PLAYER_SETUP_GAME_PARAMS',
  SET_GAME_PARAMS_SELECTED_MODE: 'SET_GAME_PARAMS_SELECTED_MODE',
  SET_GAME_PARAMS_LAST_SUBMITTED_SELECTED_MODE: 'SET_GAME_PARAMS_LAST_SUBMITTED_SELECTED_MODE',
  SET_ALL_BASEBALL_PARKS: 'SET_ALL_BASEBALL_PARKS',
  SET_GAME_PARAMS_SELECTED_MARKETS: 'SET_GAME_PARAMS_SELECTED_MARKETS',
  SET_GAME_PARAMS_SELECTED_MARKETS_PROCESSING: 'SET_GAME_PARAMS_SELECTED_MARKETS_PROCESSING',

  // Trading UI
  SET_TRADING_EVENT: 'SET_TRADING_EVENT',
  SET_TRADING_EVENT_LOADING: 'SET_TRADING_EVENT_LOADING',
  SET_ORIGINAL_TRADING_PLAYER_PROPS: 'SET_ORIGINAL_TRADING_PLAYER_PROPS',
  SET_LAST_SIMULATE_TRADING_PLAYER_PROPS: 'SET_LAST_SIMULATE_TRADING_PLAYER_PROPS',
  SET_TRADING_PLAYER_PROPS: 'SET_TRADING_PLAYER_PROPS',
  SET_IS_SIMULATE_BTN_ENABLED: 'SET_IS_SIMULATE_BTN_ENABLED',
  SET_IS_SIMULATE_BTN_LOADING: 'SET_IS_SIMULATE_BTN_LOADING',
  SET_IS_PUBLISH_BTN_ENABLED: 'SET_IS_PUBLISH_BTN_ENABLED',
  SET_IS_PUBLISH_BTN_LOADING: 'SET_IS_PUBLISH_BTN_LOADING',
  SET_TRADING_PLAYER_PROPS_LOADING: 'SET_TRADING_PLAYER_PROPS_LOADING',
  SET_TRADING_EVENT_SEARCH_ACTIVE: 'SET_TRADING_EVENT_SEARCH_ACTIVE',
  SET_TRADING_EVENT_LIST: 'SET_TRADING_EVENT_LIST',
  SET_TRADING_EVENT_LIST_LOADING: 'SET_TRADING_EVENT_LIST_LOADING',
  SET_TRADING_EVENT_MARKETS: 'SET_TRADING_EVENT_MARKETS',
  SET_TRADING_EVENT_SUSPEND_LOADING: 'SET_TRADING_EVENT_SUSPEND_LOADING',
  SET_SELECTED_TRADING_MARKETS: 'SET_SELECTED_TRADING_MARKETS',
  SET_IS_TRADING_UI_PAGE_FROZEN: 'SET_IS_TRADING_UI_PAGE_FROZEN',
  SET_IS_TRADING_UI_PAGE_SIMULATE_BTN_FROZEN: 'SET_IS_TRADING_UI_PAGE_SIMULATE_BTN_FROZEN',
  SET_IS_UNSAVED_CHANGES_ACTIVE: 'SET_IS_UNSAVED_CHANGES_ACTIVE',
  SET_TRADING_SQUAD: 'SET_TRADING_SQUAD',
  UPDATE_SINGLE_TRADING_EVENT_MARKET: 'UPDATE_SINGLE_TRADING_EVENT_MARKET',
  SET_TRADING_GAME_PARAMS_LOADING: 'SET_TRADING_GAME_PARAMS_LOADING',
  SET_TRADING_EVENT_FEEDS: 'SET_TRADING_EVENT_FEEDS',
  SET_TRADING_EVENT_SELECTED_FEED: 'SET_TRADING_EVENT_SELECTED_FEED',
  SET_IS_TRADING_SIMULATE_BY_SOCKET_CHANGE_ENABLED: 'SET_IS_TRADING_SIMULATE_BY_SOCKET_CHANGE_ENABLED',

  UPDATE_SELECTED_ODDS_FORMAT: 'UPDATE_SELECTED_ODDS_FORMAT',

  // Manual edit
  UPDATE_MANUAL_EDIT_SELECTED_SPORT: 'UPDATE_MANUAL_EDIT_SELECTED_SPORT',
  UPDATE_MANUAL_EDIT_TABLE_COLUMNS: 'UPDATE_MANUAL_EDIT_TABLE_COLUMNS',
  UPDATE_MANUAL_EDIT_TABLE_DATA: 'UPDATE_MANUAL_EDIT_TABLE_DATA',
  UPDATE_MANUAL_EDIT_SELECTED_ROW: 'UPDATE_MANUAL_EDIT_SELECTED_ROW',
  UPDATE_MANUAL_EDIT_TABLE_DATA_LOADING: 'UPDATE_MANUAL_EDIT_TABLE_DATA_LOADING',
  UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST: 'UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST',
  UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST_LOADING: 'UPDATE_MANUAL_EDIT_PLAYER_SEARCH_LIST_LOADING',
  UPDATE_MANUAL_EDIT_SEARCH: 'UPDATE_MANUAL_EDIT_SEARCH',
  UPDATE_MANUAL_EDIT_SELECTED_FEED: 'UPDATE_MANUAL_EDIT_SELECTED_FEED',
  UPDATE_MANUAL_EDIT_IS_CREATE_NEW_RECORD_ACTIVE: 'UPDATE_MANUAL_EDIT_IS_CREATE_NEW_RECORD_ACTIVE',

  // Market display configuration
  SET_DISPLAY_MARKETS_CONFIGURATION: 'SET_DISPLAY_MARKETS_CONFIGURATION',
  SET_ALL_MARKET_PARAMETERS: 'SET_ALL_MARKET_PARAMETERS',
  SET_ALL_SELECTION_PARAMETERS: 'SET_ALL_SELECTION_PARAMETERS',

  SET_MULTIVIEW_LIST_LATEST_OPTIONS: 'SET_MULTIVIEW_LIST_LATEST_OPTIONS',
  SET_MULTIVIEW_LIST_LOADING: 'SET_MULTIVIEW_LIST_LOADING',
  SET_MULTIVIEW_LIST_EVENTS_BY_ID: 'SET_MULTIVIEW_LIST_EVENTS_BY_ID',
  SET_MULTIVIEW_LIST_COMPETITIONS_BY_ID: 'SET_MULTIVIEW_LIST_COMPETITIONS_BY_ID',
  SET_MULTIVIEW_LIST_MARKETS: 'SET_MULTIVIEW_LIST_MARKETS',
  UPDATE_SINGLE_MULTIVIEW_LIST_MARKET: 'UPDATE_SINGLE_MULTIVIEW_LIST_MARKET',
  UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID: 'UPDATE_SINGLE_MULTIVIEW_LIST_EVENT_BY_ID',
  SET_MULTIVIEW_LIST_PARAMS_LOADING: 'SET_MULTIVIEW_LIST_PARAMS_LOADING',

  // Multiview
  SET_MULTIVIEW_DRAWER_EVENT: 'SET_MULTIVIEW_DRAWER_EVENT',
  SET_MULTIVIEW_DRAWER_LOADING: 'SET_MULTIVIEW_DRAWER_LOADING',
  SET_MULTIVIEW_DRAWER_PROCESSING: 'SET_MULTIVIEW_DRAWER_PROCESSING',

  // Multiview player params
  SET_MULTIVIEW_ORIGINAL_PLAYER_PARAMS: 'SET_MULTIVIEW_ORIGINAL_PLAYER_PARAMS',
  SET_MULTIVIEW_PLAYER_PARAMS: 'SET_MULTIVIEW_PLAYER_PARAMS',
  SET_MULTIVIEW_PLAYER_PARAMS_LOADING: 'SET_MULTIVIEW_PLAYER_PARAMS_LOADING',
  SET_MULTIVIEW_LAST_SIMULATE_PLAYER_PARAMS: 'SET_MULTIVIEW_LAST_SIMULATE_PLAYER_PARAMS',
  SET_MULTIVIEW_SQUAD: 'SET_MULTIVIEW_SQUAD',
  SET_MULTIVIEW_SIMULATE_BTN_ENABLED: 'SET_MULTIVIEW_SIMULATE_BTN_ENABLED',
  SET_MULTIVIEW_SIMULATE_BTN_FROZEN: 'SET_MULTIVIEW_SIMULATE_BTN_FROZEN',
  SET_MULTIVIEW_SIMULATE_BTN_LOADING: 'SET_MULTIVIEW_SIMULATE_BTN_LOADING',
  SET_MULTIVIEW_SUBMIT_BTN_ENABLED: 'SET_MULTIVIEW_SUBMIT_BTN_ENABLED',
  SET_MULTIVIEW_SUBMIT_BTN_LOADING: 'SET_MULTIVIEW_SUBMIT_BTN_LOADING',
  SET_IS_MULTIVIEW_DRAWER_FROZEN: 'SET_IS_MULTIVIEW_DRAWER_FROZEN',
  SET_MULTIVIEW_NUMBER_OF_SIMULATE_ATTEMPTS: 'SET_MULTIVIEW_NUMBER_OF_SIMULATE_ATTEMPTS',
  SET_IS_MULTIVIEW_SIMULATE_BY_SOCKET_CHANGE_ENABLED: 'SET_IS_MULTIVIEW_SIMULATE_BY_SOCKET_CHANGE_ENABLED',

  // Market group
  UPDATE_MARKET_GROUP_MARKET_CODE_LIST: 'UPDATE_MARKET_GROUP_MARKET_CODE_LIST',
  UPDATE_MARKET_GROUP_MARKET_CODE_LIST_LOADING: 'UPDATE_MARKET_GROUP_MARKET_CODE_LIST_LOADING',

  // Odds checker
  UPDATE_ODDS_CHECKER_FILTERS_META: 'UPDATE_ODDS_CHECKER_FILTERS_META',
  UPDATE_ODDS_CHECKER_EVENTS: 'UPDATE_ODDS_CHECKER_EVENTS',
  UPDATE_ODDS_CHECKER_EVENT_BY_ID: 'UPDATE_ODDS_CHECKER_EVENT_BY_ID',
  SET_ODDS_CHECKER_EVENTS_LOADING: 'SET_ODDS_CHECKER_EVENTS_LOADING',
  UPDATE_ODDS_CHECKER_QUERY_OPTIONS: 'SET_ODDS_CHECKER_QUERY_OPTIONS',
};
